export default {
    props: ["value"],
    template: `
        <div>
            <input type="text" class="form-control" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/>
        </div>`,
    data: function() {
        return {
            isInputActive: false
        }
    },
    computed: {
        displayValue: {
            get: function() {
                let newValue = Math.round(this.value * 1000) / 1000;
                newValue = newValue.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return newValue;
            },
            set: function(modifiedValue) {
                let newValue = parseFloat(modifiedValue.toString().replace(' ', '').replace(',', '.'));
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                this.$emit('input', newValue);
            }
        }
    }
}