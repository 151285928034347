import api from "../api/api";
import TokenService from "./token.service";
import Utilities from "./utilities";
import EventBus from "../common/EventBus";

const setup = () => {
    api.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();

            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token; 
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    )

    api.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            if (err.response) {
                if (err.response.status == 401) {
                    EventBus.dispatch("logout");
                    Utilities.alertmsg("Votre session a expiré, veuillez vous reconnecter");
                    return false;
                }
            }
            return Promise.reject(err);
        }
    )
}

export default setup;