import AuthService from '../../services/auth.service';
import TokenService from '../../services/token.service';
import router from '../../router/index'

const user = TokenService.getUser();
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        AuthService.login({ email, password })
            .then(
                user => {
                    if (user) {
                        commit('loginSuccess', user);
                        router.push('/');
                    }
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    }
};

