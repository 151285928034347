import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import VueTippy, { TippyComponent } from "vue-tippy";
import CurrencyInput from './components/global/currency-input';

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);


import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
import "tippy.js/themes/light.css";

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

Vue.filter('price', function (value) {
  if (!value) return '0'
      return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(value);
});

Vue.filter('percent', function (value) {
  if (value === 0) return '0%'
  if (!value) return ''
  let newValue = Math.round(value * 100) / 100;
  return newValue.toString().replace('.', ',') + '%';
});

Vue.component('currency-input', CurrencyInput);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
