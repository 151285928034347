import store from '@/state/store'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/reset-password',
    name: 'Reset-password',
    component: () => import('../views/pages/account/reset-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  }, {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboard/index')
  },
  {
    path: '/prospects',
    name: 'Prospects',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/prospects')
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/customers')
  },
  {
    path: '/customer/:id',
    name: 'Customer detail',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/customer')
  },
  {
    path: '/services',
    name: 'services',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/services')
  },
  {
    path: '/service_categories',
    name: 'service_categories',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/service_categories')
  },
  {
    path: '/sales',
    name: 'sales',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/sales')
  },
  {
    path: '/sell/:id/quotation',
    name: 'Quotation',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/quotation')
  },
  {
    path: '/sell/:id/worksheet',
    name: 'worksheet',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/worksheet')
  },
  {
    path: '/sell/:id/worksheet/pdf',
    name: 'worksheet',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/worksheet-pdf')
  },
  {
    path: '/sell/:id',
    name: 'Sell detail',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/sell-detail')
  },
  {
    path: '/sell/:id/pdf',
    name: 'PDF',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/sell-pdf')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/users')
  },
]