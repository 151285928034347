import auth from "../api/auth";
import TokenService from "./token.service";
import Utilities from "./utilities";

class AuthService {
    login(user) {
        return auth.post('/token', user)
            .then(
                response => {
                    if (response && response.status == 200) {
                        var token = response.data;
                        if (!response.data.data.enabled) {
                            Utilities.alertmsg("Votre accès n'est pas autorisé.");
                            return null;
                        }
                        TokenService.setUser(token);
                        return token;
                    }
                    return null;
                },
                (error) => {
                    Utilities.alertmsg(error.response.data.message);
                    return null;
                }
            )
    }

    logout() {
        TokenService.removeUser();
    }
}

export default new AuthService();