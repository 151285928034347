import Swal from "sweetalert2";

class Utilities {
    successmsg(msg) {
        Swal.fire({
            title: "Succès",
            text: msg,
            timer: 1500,
            icon: "success",
            confirmButtonColor: "#2e58a6",
            confirmButtonText: "Fermer",
        });
    }
    
    errormsg() {
        Swal.fire({
            title: "Erreur",
            text: "Veuillez réessayer plus tard",
            icon: "error",
            confirmButtonColor: "#2e58a6",
            confirmButtonText: "Fermer",
        });
    }
    
    alertmsg(msg) {
        Swal.fire({
            title: "Erreur",
            text: msg,
            icon: "error",
            confirmButtonColor: "#2e58a6",
            confirmButtonText: "Fermer",
        });
    }

    currentDate() {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1 }/${current.getFullYear()}`;
        return date;
    }
}

export default new Utilities();