<template>
  <div id="app">
    <router-view :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales" />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import EventBus from "./common/EventBus";

export default {
  name: "app",
  page: {
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
    logUserOut() {
      this.$store.dispatch('auth/logout');
      if (this.$router.currentRoute.path != '/login') {
        this.$router.push('/login');
      }
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.clearNotification();
    },
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.data;
      }
      return null;
    },
    showAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return (this.currentUser.roles.includes('ROLE_SUPER_ADMIN') || this.currentUser.roles.includes('ROLE_ADMIN'));
      }
      return false;
    },
    showSuperAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_SUPER_ADMIN');
      }
      return false;
    },
    showSuperSales() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_SUPER_SALES');
      }
      return false;
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logUserOut();
    });
  }
};
</script>