class TokenService {
    getLocalAccessToken() {
        if (localStorage.getItem("user") && typeof localStorage.getItem('user') != "undefined") {
            const user = JSON.parse(localStorage.getItem("user"));
            return user.token;
        }
        return false;
    }

    getUser() {
        if (localStorage.getItem("user") && typeof localStorage.getItem('user') != "undefined") {
            return JSON.parse(localStorage.getItem("user"));
        }
        return false;
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
    }
}

export default new TokenService();